// src/components/LandingIntroSection.js
import React from 'react';
import './LandingIntroSection.css';

function LandingIntroSection() {
  return (
    <section className="landing-intro">
      <div className="intro-content">
        <h2>WOMEN'S SECOND-HAND CLOTHING – BUY & SELL WITH STYLE<br />

STORIES, STYLES, AND SUSTAINABILITY AT LADYFIRST.ME</h2>
        <p>
        Fashion brings confidence. Tells stories. Connects women everywhere. At Ladyfirst.me, we believe in the power of pre-loved fashion to create impact—on the planet, on your wallet, and in your wardrobe.

Whether you're looking to give your clothes a second life or shop affordable, stylish second-hand finds—we're here to empower every woman. From casual wear to elegant gowns, timeless jackets to trending fits—our marketplace brings fashion full circle.

Ladyfirst.me is more than a platform—it's a movement. A home for style lovers, conscious shoppers, and bold resellers. Whether you're selling a story or finding your next look, we're here to make fashion more sustainable, inclusive, and inspiring.

We collaborate with women, creators, and community leaders to shape a future where fashion feels good and does good. We're not just changing wardrobes—we're changing the world, one outfit at a time.
        </p>
        <div className="intro-logo">
          <img src="/logo192.png" alt="Logo" />
        </div>
      </div>
    </section>
  );
}

export default LandingIntroSection;
