// src/UploadProduct.js
import React from 'react';
import ProductUpload from './ProductUpload';

function UploadProduct() {
  return (
    <div>
      <ProductUpload />
    </div>
  );
}

export default UploadProduct;
